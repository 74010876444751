exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-complete-tsx": () => import("./../../../src/pages/contact-complete.tsx" /* webpackChunkName: "component---src-pages-contact-complete-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-illegible-tsx": () => import("./../../../src/pages/illegible.tsx" /* webpackChunkName: "component---src-pages-illegible-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mode-select-tsx": () => import("./../../../src/pages/mode-select.tsx" /* webpackChunkName: "component---src-pages-mode-select-tsx" */),
  "component---src-pages-opening-tsx": () => import("./../../../src/pages/opening.tsx" /* webpackChunkName: "component---src-pages-opening-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-readable-tsx": () => import("./../../../src/pages/readable.tsx" /* webpackChunkName: "component---src-pages-readable-tsx" */)
}

