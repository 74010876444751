import React from 'react'

type ModeType = 'illegible' | 'readable'

export type GlobalState = {
  mode: ModeType
  mute: boolean
  setMode: (mode: ModeType) => void
  setMute: (flag: boolean) => void
}

const state: GlobalState = {
  mode: 'readable',
  mute: false,
  setMode: () => {},
  setMute: () => {},
}

const GlobalContext = React.createContext(state)

type Props = {
  children: React.ReactNode
}
const GlobalContextProvider: React.FC<Props> = (props) => {
  const [mode, setStateMode] = React.useState<ModeType>('readable')
  const [mute, setStateMute] = React.useState<boolean>(false)

  return (
    <GlobalContext.Provider
      value={{
        mode,
        mute,
        setMode: (mode: ModeType) => {
          setStateMode(mode)
        },
        setMute: (flag: boolean) => {
          setStateMute(flag)
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export { GlobalContextProvider }
